





















































































































































































































































































































































// core
import Vue from "vue";
// services
import { accountingCurrencyService } from "@/services-v2/accounting-currency.service";
import { accountingAccountService } from "@/services-v2/accounting-account.service";
import { accountingAccountType } from "@/services-v2/accounting-account-type.service";
// interface
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { ResponseListAccountingCurrency } from "@/models/interface-v2/currency.interface";
import { ResponseListAccountingAccountType } from "@/models/interface-v2/accounting-type.interface";
import { RequestAccountingAccountCreate, ResponseAccountingAccount, ResponseListAccountingAccount } from "@/models/interface-v2/accounting-account.interface";
// utility
import { debounceProcess } from "@/helpers/debounce";
import { NORMAL_BALANCE } from "@/models/enums/normal-balance.enum";
import { getauthorities } from "@/helpers/cookie";

export default Vue.extend({
  data() {
    this.handleSearchParent = debounceProcess(this.handleSearchParent, 200);
    this.searchMasterCurrency = debounceProcess(this.searchMasterCurrency, 200);
    this.getlistOfAccountType = debounceProcess(this.getlistOfAccountType, 200);
    return {
      firstSearch: false as boolean,
      dataListAccountType: {} as ResponseListAccountingAccountType,
      dataListCoa: {} as ResponseListAccountingAccount,
      dataListCoaModal: {} as ResponseListAccountingAccount,
      page: 0,
      limit: 20,
      search: "" as string,
      sort: "" as string,
      authPrivilege: [] as string[],
      typemodal: "" as string | "edit" | "create" | "detail",
      loadingTable: false as boolean,
      titlemodalOpen: "COA" as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      labelCol: { sm: { span: 24 }, md: { span: 12 } },
      wrapperCol: { sm: { span: 24 }, md: { span: 12 } },
      form: {
        accountCode: "",
        accountType: "",
        normalBalance: "",
        accountName: "",
        currency: "",
        isParent: false,
        parentId: "",
        status: false
      },
      rules: {
        accountCode: [{ required: true, message: this.$t("lbl_validation_required_error"), trigger: "change" }],
        accountType: [{ required: true, message: this.$t("lbl_validation_required_error"), trigger: "change" }],
        normalBalance: [{ required: true, message: this.$t("lbl_validation_required_error"), trigger: "change" }],
        parentId: [{ required: true, message: this.$t("lbl_validation_required_error"), trigger: "change" }],
      },
      formRules: {
        code: {
          label: this.$t("lbl_account_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
        },
        balance: {
          label: this.$t("lbl_normal_balance"),
          name: "balance",
          placeholder: this.$t("lbl_type_here"),
        },
        type: {
          label: this.$t("lbl_account_type"),
          name: "type",
          placeholder: this.$t("lbl_type_here"),
        },
        accountname: {
          label: this.$t("lbl_account_name"),
          name: "accountname",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["accountname", {}],
        },
        currency: {
          label: this.$t("lbl_currency"),
          name: "currency",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["currency", {}],
        },
        status: {
          label: this.$t("lbl_active"),
          name: "active",
          decorator: ["active"],
        },
        parent: {
          label: this.$t("lbl_parent_account"),
          name: "parent",
          placeholder: this.$t("lbl_type_here"),
        },
        parentswitch: {
          label: this.$t("lbl_parent"),
          name: "parentswitch",
        },
      },
      loading: {
        currency: false
      },
      dtListCurrency: {} as ResponseListAccountingCurrency,
      coaId: ""
    };
  },
  mounted() {
    this.getListCOA();
    this.getlistOfAccountType();
    this.handleSearchParent();
    this.searchMasterCurrency();
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "accounting-account") {
          this.authPrivilege = dataAuth.value;
        }
      });
    },
    rowClassName(record: ResponseAccountingAccount): string {
      return record.parent ? "is-parent" : "";
    },
    handleSearchParent(value = "") {
      let params = {
        page: 0,
        limit: 20,
      } as RequestQueryParamsModel;
      if (value) params.search = `description~*${value}*`;
      this.firstSearch = true;
      accountingAccountService.getListCOA(params)
      .then((data: ResponseListAccountingAccount) => this.dataListCoaModal = data)
      .catch(() => this.$message.error(this.$t("notif_process_fail").toString()));
    },
    reponseDeleteTable(record): void {
      const path = record.id;
      accountingAccountService.deleteCoa(path)
      .then(() => {
        this.resetFilter(false);
        this.getListCOA();
      })
      .catch(() => this.$message.error(this.$t("notif_process_fail").toString()));
    },
    reponseEditTable(response: ResponseAccountingAccount): void {
      this.typemodal = "edit";
      this.titlemodalOpen = `${this.$t("lbl_edit")} COA`;
      this.coaId = response.id;
      this.resetFilter(false);
      this.getDetailCOA(response.id);
    },
    resetFilter(getlist: boolean): void {
      this.search = "";
      this.firstSearch = false;
      if (getlist) this.getListCOA();
    },
    getlistOfAccountType(search = "") {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        sorts: "createdDate:desc"
      };
      if (search) params.search = `name~*${search}*`;
      accountingAccountType.listOfAccountType(params)
      .then((res: ResponseListAccountingAccountType) => {
        this.dataListAccountType = res;
      })
      .catch(() => this.$message.error(this.$t("notif_process_fail").toString()));
    },
    getListCOA(): void {
      const params: RequestQueryParamsModel = {
        page: this.page,
        limit: this.limit,
        // sorts: "createdDate:desc"
      };
      if (this.search) params.search = this.search;
      this.loadingTable = true;
      accountingAccountService.getListCOA(params)
      .then((res: ResponseListAccountingAccount) => {
        this.dataListCoa = res;
        this.dataListCoa.data.forEach((el, i) => el["key"] = i);
      })
      .catch(() => this.$message.error(this.$t("notif_process_fail").toString()))
      .finally(() => this.loadingTable = false);
    },
    getDetailCOA(id: string): void {
      this.modalOpen = true;
      accountingAccountService.getDetailCOA(id)
      .then((res: ResponseAccountingAccount) => {
        this.form = {
          accountCode: res.code,
          accountType: res.accountType?.id || "-",
          accountName: res.description || "-",
          parentId: res.parentId || "",
          isParent: res.parent,
          normalBalance: res.normalBalance,
          currency: res.currency?.id || "",
          status: res.active
        };
        const idxType = this.dataListAccountType.data.findIndex(x => x.id === res.accountType?.id);
        if (idxType === -1) this.dataListAccountType.data.push({
          createDate: "",
          description: res.accountType?.description || "",
          id: res.accountType?.id || "",
          modifiedDate: "",
          name: ""
        });

        const idxParent = this.dataListCoaModal.data.findIndex(x => x.id === res.parentId);
        if (idxParent === -1) this.dataListCoaModal.data.push({
          accountType: null,
          active: false,
          balance: 0,
          currency: null,
          code: "",
          createdDate: "",
          description: res.parentName || "",
          id: res.parentId || "",
          modifiedDate: "",
          normalBalance: NORMAL_BALANCE.CREDIT,
          parent: false,
          parentId: "",
          parentName: "",
        });
      })
      .catch(() => this.$message.error(this.$t("notif_process_fail").toString()));
    },
    reponseSearchInput(response: string): void {
      if (response) {
        this.search =
          "code~*" + response + "*_OR_description~*" + response + "*";
        this.firstSearch = true;
      } else {
        this.search = "";
      }
      this.getListCOA();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 0;
      this.getListCOA();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page - 1;
      this.getListCOA();
    },
    resetForm(): void {
      this.form = {
        accountCode: "",
        accountType: "",
        normalBalance: "",
        accountName: "",
        currency: "",
        isParent: false,
        parentId: "",
        status: false
      };
    },
    createNew(): void {
      this.resetForm();
      this.modalOpen = true;
      this.typemodal = "create";
      this.titlemodalOpen = `${this.$t("lbl_create_new")} COA`;
      this.resetFilter(false);
    },
    createNewCOA(datapost: RequestAccountingAccountCreate): void {
      accountingAccountService.createCoa(datapost)
      .then((res) => {
        if (res) {
          this.resetForm();
          this.getListCOA();
          this.$message.success(this.$t("notif_create_success").toString());
          this.modalOpen = false;
        }
      })
      .finally(() => (this.isFormSubmitted = false));
    },
    updateCOA(datapost: RequestAccountingAccountCreate): void {
      accountingAccountService.updateCoa(datapost, this.coaId)
      .then((res) => {
        if (res) {
          this.resetForm();
          this.getListCOA();
          this.$message.success(this.$t("notif_update_success").toString());
          this.modalOpen = false;
        }
      })
      .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(type: string | "create" | "edit"): void {
      const formcoa = this.$refs.formCoa as any;
      formcoa.validate((valid: boolean) => {
        if (valid) {
          const datapost: RequestAccountingAccountCreate = {
            code: this.form.accountCode,
            description: this.form.accountName,
            parentId: this.form.parentId,
            accountTypeId: this.form.accountType,
            normalBalance: this.form.normalBalance as NORMAL_BALANCE,
            active: this.form.status,
            parent: this.form.isParent,
            currencyId: this.form.currency
          };
          this.isFormSubmitted = true;
          if (type === "create") {
            this.createNewCOA(datapost);
          } else {
            this.updateCOA(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.resetForm();
      this.modalOpen = false;
    },
    getListMasterCurrency(params: RequestQueryParamsModel): Promise<ResponseListAccountingCurrency> {
      return accountingCurrencyService.listOfMasterCurrency(params);
    },
    async searchMasterCurrency(search = ""): Promise<void> {
      try {
        this.loading.currency = true;
        const params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          sorts: "createdDate:desc"
        };
        if (search) params.search = `currencyCode~*${search}*`;
        const res = await this.getListMasterCurrency(params);
        this.dtListCurrency = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.currency = false;
      }
    }
  },
});
