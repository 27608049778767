import { Api } from "@/models/class/api.class";
import { ResponseListAccountingAccountType } from "@/models/interface-v2/accounting-type.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class AccountingAccountTypeServices extends HttpClient {
  constructor() {
    super()
  }

  listOfAccountType(params: RequestQueryParamsModel): Promise<ResponseListAccountingAccountType> {
    return this.get<ResponseListAccountingAccountType>(Api.AccountType, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const accountingAccountType = new AccountingAccountTypeServices()
